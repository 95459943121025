import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { ProductCardComponent } from './products/product-card/product-card.component';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { PriceListMontageComponent } from './price-list/price-list-montage/price-list-montage.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { PriceListServisComponent } from './price-list/price-list-servis/price-list-servis.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    ProductListComponent,
    ProductCardComponent,
    ProductDetailComponent,
    PriceListMontageComponent,
    ContactPageComponent,
    PageNotFoundComponent,
    PriceListServisComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

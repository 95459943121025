<div class="product-card">
    <div class="product-thumb">
        <a href="/proizvod"><img src="https://www.toshiba-aircon.co.uk/wp-content/uploads/2017/09/New_outdoor_unit_from_Toshiba-4.jpg" alt="slika"></a>
    </div>
    <div class="product-details">
        <h4><a href="/proizvod">Toshiba klima</a></h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>
        <div class="product-bottom-details">
            <div class="product-price"><small>$96.00</small>$230.99</div>
            <div class="product-links">
                <a><i class="fa fa-shopping-cart"></i></a>
            </div>
        </div>
    </div>
</div>

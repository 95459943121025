<link href='https://fonts.googleapis.com/css?family=Roboto:500,900,100,300,700,400' rel='stylesheet' type='text/css'>

<nav class="stroke">
  <ul>
    <li><a href="/">Početna</a></li>
    <li class="dropdown"><a>Cjenik</a>
      <div class="dropdown-content">
        <a href="/cjenik-servis" class="drop-link">Servis</a>
        <a href="/cjenik-montaza" class="drop-link">Montaža</a>
      </div>
    </li>
    <li><a href="/kontakt">Kontakt</a></li>
  </ul>
</nav>

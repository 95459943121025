<body>
<div class="wrapper">
	  <div>
	    <table>
	      <tbody>
	        <tr>

	          <th style="text-align:center;width:60%;">
	            <strong>Cjenik standardne montaže klima uređaja</strong></th>
	          <th style="text-align:center;width:20%;">
	            <strong>Cijena</strong></th>

	        </tr>

	        <tr>
	          <td style="text-align:right">Zidni ili podni uređaj snage do 4,9 kW</td>
	          <td>1.875,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Zidnog ili podni uređaj snage do 5,9 kW</td>
	          <td>2.200,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Zidni ili podni uređaj snage do 6,9 kW</td>
	          <td>2.800,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Zidnog ili podni uređaj snage do 7 do 10 kW</td>
	          <td>3.000,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Kazetni klima uređaj snage do 4,9 kW</td>
	          <td>4.000,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Kazetni klima uređaj snage od 5 do 7,9 kW</td>
	          <td>5.000,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Kazetni klima uređaj snage od 8 do 10 kW</td>
	          <td>5.500,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Dual klima uređaj</td>
	          <td>3.500,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Trial klima uređaj</td>
	          <td>5.000,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Quadral klima uređaj</td>
	          <td>6.500,00</td>
	        </tr>

	        <tr>
	          <td colspan="2" style="text-align:right">Cijene su s uračunatim PDV-om</td>
	        </tr>

	        <tr>
	          <td colspan="2" style="text-align:left"><strong>Standardna montaža klima uređaja</strong> podrazumijeva
	            montažu
	            unutarnje i vanjske jedinice na visini do 3 metra, bez upotrebe skele ili auto-dizalice na području cijele
	            Republike Hrvatske i sastoji se od:<br>
	            <ul><br>
	              <li>do 3 metra duljine plinske i elektro instalacije između unutarnje i vanjske jedinice,</li>
	              <li>do 3 metra duljine PVC kanala dimenzija 65&#215;50 mm za postavljanje instalacije – nadžbukno,</li>
	              <li>do 3 metra duljine PVC kanala dimenzija 18x18mm sa napojnim el. kabelom – nadžbukno,</li>
	              <li>do 3 metra cijevi za odvod vode, slobodnim padom u pripremljeni odvod,</li>
	              <li>1 par pocinčanih nosača za montažu vanjske jedinice</li>
	              <li>1 proboj zida, debljine do 55 cm,</li>
	              <li>potrošni materijal (izolacijska traka, vezice, silikon, gips i ostali potrebni materijal za montažu),
	              </li>
	              <li>spajanje napajanja na postojeću električnu instalaciju,</li>
	              <li>vakumiranje,</li>
	              <li>puštanje u pogon,</li>
	              <li>testiranje rada,</li>
	              <li>čišćenje mjesta rada,</li>
	              <li>ovjera garancije,</li>
	              <li>kratke upute o korištenju klima uređaja</li>
	            </ul>
	            Ukoliko je montaža zahtjevnija od standardne montaže, cijena dodatnih radova naplaćuje se po cjeniku za
	            dodatne
	            radove. Važna napomena &#8211; uporaba skele ili auto-dizalice naplaćuje se posebno, te se ugovara
	            unaprijed.
	            Za otoke i lokacije koje nisu pokrivene našom mrežom ovlaštenih montažera moguća je naplata putnih
	            troškova.
	          </td>
	        </tr>

	      </tbody>
	    </table>
	  </div>

	  <div>
	    <table>
	      <tbody>
	        <tr>
	          <th style="text-align:center;width:60%;"><strong>Cjenik montaže klima uređaja na
	              postavljene instalacije</strong></th>
	          <th style="text-align:center;width:20%;"><strong>Cijena</strong></th>
	        </tr>

	        <tr>
	          <td style="text-align:right">Zidni ili podni uređaj snage do 4,9 kW</td>
	          <td>1.000,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Zidnog ili podni uređaj snage od 5 do 7,9 kW</td>
	          <td>1.200,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Zidni ili podni uređaj snage od 8 do 10 kW</td>
	          <td>1.600,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Dual klima uređaj</td>
	          <td>1.900,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Trial klima uređaj</td>
	          <td>2.500,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Quadral klima uređaj</td>
	          <td>3.000,00</td>
	        </tr>

	        <tr>
	          <td colspan="2" style="text-align:right">Cijene su s uračunatim PDV-om</td>
	        </tr>

	        <tr>
	          <td colspan="2" style="text-align:left"><strong>Montaža klima uređaja na postavljene instalacije</strong>
	            podrazumijeva montažu unutarnje i vanjske jedinice na visini do 3 metra, bez upotrebe skele ili
	            auto-dizalice na području cijele Republike Hrvatske i sastoji se od:<br>
	            <ul><br>
	              <li>postavljanje i povezivanje unutarnje jedinice,</li>
	              <li>postavljanje i povezivanje vanjske jedinice,</li>
	              <li>potrošni materijal (izolacijska traka, vezice, silikon, gips i ostali potrebni materijal za montažu),
	              </li>
	              <li>spajanje napajanja na postojeću električnu instalaciju,</li>
	              <li>vakumiranje,</li>
	              <li>puštanje u pogon,</li>
	              <li>testiranje rada,</li>
	              <li>čišćenje mjesta rada,</li>
	              <li>ovjera garancije,</li>
	              <li>upute o korištenju klima uređaja</li>
	            </ul>
	            Montaža klima uređaja na postavljene instalacije podrazumijeva da je instalacija ispravno postavljena i da
	            odgovara klimi koja se montira. Važno je da odgovara promjer i duljina bakrenih izoliranih cijevi kao i
	            raspon između postavljenih nosača vanjske jedinice. Instalacije za unutarnju jedinicu moraju biti
	            postavljene oko 40 cm. od plafona a crijevo ili cijev za odvod vode, kondenzata mora biti ispravno
	            postavljeno tako da voda neometano otječe. Između unutarnje i vanjske jedinice treba biti postavljen
	            komunikacijski kabel 5×1,5 mm². Bakrene cijevi na krajevima moraju biti zatvorene ili zavarene, ako to nije
	            slučaj neophodno je neutralnim plinom, dušikom propuhati a po potrebi i provjeriti nepropusnost instalacija
	            tlačenjem dušikom. Napajanje odnosno napojni kabel 3×2,5 mm² mora biti doveden na unutarnju ili na vanjsku
	            jedinicu ovisno o modelu klima uređaja. U slučaju da instalacije u potpunosti ne odgovaraju, iste je
	            potrebno prilagoditi što povećava cijenu montaže.
	          </td>
	        </tr>

	      </tbody>
	    </table>
	  </div>

	  <div>
	    <table>
	      <tbody>
	        <tr>
	          <th style="text-align:center;width:60%;"><strong>Cjenik demontaže klima
	              uređaja</strong></th>
	          <th style="text-align:center;width:20%;"><strong>Cijena</strong></th>
	        </tr>

	        <tr>
	          <td style="text-align:right">Zidni ili podni uređaj snage do 4 kW</td>
	          <td>500,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Zidnog ili podni uređaj snage od 4 do 6 kW</td>
	          <td>600,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Zidni ili podni uređaj snage od 6 do 8 kW</td>
	          <td>700,00</td>
	        </tr>
	        <tr>
	          <td style="text-align:right">Zidni ili podni uređaj snage od 8 do 10 kW</td>
	          <td>900,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Dual klima uređaj</td>
	          <td>700,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Trial klima uređaj</td>
	          <td>900,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Quadral klima uređaj</td>
	          <td>1.400,00</td>
	        </tr>

	        <tr>
	          <td colspan="2" style="text-align:right">Cijene su s uračunatim PDV-om</td>
	        </tr>

	        <tr>
	          <td colspan="2" style="text-align:left"><strong>Demontaža klima uređaja</strong> podrazumijeva demontažu
	            unutarnje i vanjske jedinice na visini do 3 metra, bez upotrebe skele ili auto-dizalice.<br>
	            Navedena cijena se odnosi za grad Zagreb ukoliko se radi samo demontaža ili uz montažu novog klima uređaja
	            na području cijele Republike Hrvatske. Demontaža klima uređaja sastoji se od:<br><br>
	            Ukoliko je uređaj ispravan:<br>
	            <ul>
	              <li>kompletan plin se komprimira u vanjsku jedinicu,</li>
	              <li>ventili i spojevi se zaštićuju izolacijskom trakom,</li>
	              <li>unutarnja i vanjska jedinica se zaštićuju zaštitnom folijom, te je takav klima uređaj pripremljen za
	                montažu na drugu lokaciju ili skladištenje.</li>
	            </ul>
	            Ukoliko je uređaj neispravan:
	            <ul>
	              <li>kompletan plin se rekuperatorom povlači u bocu i ekološki zbrinjava,</li>
	              <li>na zahtjev kupca, neispravan klima uređaj odvozimo i ekološki zbrinjavamo</li>
	            </ul>
	          </td>
	        </tr>
	      </tbody>
	    </table>
	  </div>

	  <div>
	    <table>
	      <tbody>
	        <tr>
	          <th style="text-align:center;width:60%;"><strong>Cjenik dodatnih radova</strong>
	          </th>
	          <th style="text-align:center;width:20%;"><strong>Cijena</strong></th>
	        </tr>

	        <tr>
	          <td style="text-align:right">Dodatni odvod vode &#8211; kondenzata</td>
	          <td>80,00</td>
	        </tr>

	        <tr>
	          <td colspan="2" style="text-align:left">Dodatni odvod vode &#8211; kondenzata pri montaži klima uređaja, a
	            koja prelazi duljinu od 3m. Naplaćuje se po dužnom metru, te uključuje: <ul>
	              <li>Crijevo Kaoflex 16/20 mm ili PNT kruta cijev </li>
	              <li>PVC kanalica po potrebi</li>
	              <li>usluga postavljanja</li>
	            </ul>
	          </td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Dodatno napajanje klima uređaja</td>
	          <td>80,00</td>
	        </tr>

	        <tr>
	          <td colspan="2" style="text-align:left">Dodatno napajanje pri montaži klima uređaja, a koje prelazi duljinu
	            od
	            3m. Naplaćuje se po dužnom metru, te uključuje: <ul>
	              <li>napojni elektro kabel 3&#215;2,5mm2</li>
	              <li>PVC kanalica po potrebi</li>
	              <li>usluga postavljanja</li>
	            </ul>
	          </td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Dodatno štemanje ciglenog zida (naplaćuje se po dužnom metru)</td>
	          <td>350,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Dodatno štemanje betonskog zida (naplaćuje se po dužnom metru)</td>
	          <td>350,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Dodatna frigo i elektroinstalacija za klima uređaj snage do 4,9 kW</td>
	          <td>250,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Dodatna frigo i elektroinstalacija za klima uređaj snage od 5 &#8211; 7 kW</td>
	          <td>300,00</td>
	        </tr>

	        <tr>
	          <td style="text-align:right">Dodatna frigo i elektroinstalacija za klima uređaj snage od 7,1 &#8211; 10 kW
	          </td>
	          <td>350,00</td>
	        </tr>

	        <tr>
	          <td colspan="2" style="text-align:left">Dodatna instalacija između unutarnje i vanjske jedinice klima
	            uređaja,
	            a koja se postavlja u PVC kanalicu, a prelazi duljinu od 3 metra (obračunava se po dužnom metru), te
	            uključuje: <ul>
	              <li>PVC kanalica</li>
	              <li>dvije bakrene predizolirane cijefi za freon</li>
	              <li>elektrokomunikacijski kabel 5&#215;1,5 mm2 &#8211; veza između vanjske i unutarnje jedinice</li>
	              <li>crijevo za odvod kondenzata iz unutarnje jedinice (osim kad se kondenzat unutarnje jedinice odvodi
	                drugom trasom)</li>
	              <li>usluga postavljanja</li>
	            </ul>
	          </td>
	        </tr>
	        <tr>
	          <td colspan="2" style="text-align:right">Cijene su s uračunatim PDV-om</td>
	        </tr>
	      </tbody>
	    </table>
	  </div>
	</div>
</body>

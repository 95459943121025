<link href='https://fonts.googleapis.com/css?family=Roboto:500,900,100,300,700,400' rel='stylesheet' type='text/css'>

<body>
  <div class="img-text">
    <img class="logo" src="../assets/novi-logo.png" alt="logo">

    <div class="text-container">
      <br>
      <p><strong>CLIVENT</strong></p>
      <br>
      <br>
      <p>Prodaja i servis klima uređaja, rashladne tehnike i grijanja</p>
      <br>
      <br>
      <p>Licencirani smo od Ministarstva zaštite okoliša prostornog uređenja i graditeljstva i
        tijekom svih aktivnosti brinemo o primjeni svih relevantnih zakonskih i strukovnih propisa te zadovoljenja
        svih
        ekoloških normi.</p>
      <br>
      <br>
      <p>Specijalizirani smo za klimatizaciju, ventilaciju, rashladne sustave i sustave grijanja, elektroinstalacijske
        i
        vodoinstalaterske radove i održavanje strojarskih instalacija.</p>
      <br>
      <br>
    </div>
    <div class="title">
      <br>
      <p><strong>CLIVENT</strong></p>
      <br>
      <br>
      <p>Prodaja i servis klima uređaja, rashladne tehnike i grijanja</p>
      <br>
      
    </div>
    <div class="details">
      <br>
      <p>Licencirani smo od Ministarstva zaštite okoliša prostornog uređenja i graditeljstva i
        tijekom svih aktivnosti brinemo o primjeni svih relevantnih zakonskih i strukovnih propisa te zadovoljenja
        svih
        ekoloških normi.</p>
      <br>
      <br>
      <p>Specijalizirani smo za klimatizaciju, ventilaciju, rashladne sustave i sustave grijanja, elektroinstalacijske
        i
        vodoinstalaterske radove i održavanje strojarskih instalacija.</p>
      <br>
    </div>
  </div>
  <div class="img-container">
    <img src="../assets/mitsubishi.jpg" alt="Mitsubishi logo">
    <img src="../assets/gree.png" alt="Gree logo">
    <img src="../assets/azuri.jpg" alt="Azuri logo">

    <img src="../assets/climaveneta.png" alt="Climaveneta logo">
    <img src="../assets/midea.png" alt="Midea logo">
    <img src="../assets/samsung-square.png" alt="Samsung logo">
  </div>

</body>

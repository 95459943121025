import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { HomeComponent } from './home/home.component';
import { PriceListMontageComponent } from './price-list/price-list-montage/price-list-montage.component';
import { PriceListServisComponent } from './price-list/price-list-servis/price-list-servis.component';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', component: HomeComponent }, //default route
  //{ path: 'proizvodi', component: ProductListComponent},
  //{ path: 'proizvod', component: ProductDetailComponent},
  { path: 'cjenik-montaza', component: PriceListMontageComponent},
  { path: 'cjenik-servis', component: PriceListServisComponent},
  { path: 'kontakt', component: ContactPageComponent},
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<body>
  <div class="img-text">
    <img src="../assets/novi-logo.png" alt="logo">

    <div class="contact-container">
      
        <div class="contact">
          <span><strong>SERVIS</strong></span>
          <span><i class="fa fa-phone"> +385 91 605 5802</i></span>
          <span><i class="fa fa-phone"> +385 98 994 1004</i></span>
          <span><i class="fa fa-envelope"> servis@clivent.hr</i></span>
        </div>
        
        <div class="contact">
          <span><strong>PRODAJA</strong></span>
          <span><i class="fa fa-envelope"> info@clivent.hr</i></span>
          <span><i class="fa fa-envelope"> denis@clivent.hr</i></span>
          <span><i class="fa fa-envelope"> mia@clivent.hr</i></span>
        </div>
      </div>
    
  </div>
</body>

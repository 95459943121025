<body>
  <div>
    <table>
      <tr>
        <th style="text-align:center;width:60%;"><strong>Godišnji servis monosplit klima
            uređaja</strong></th>
        <th style="text-align:center;width:20%;"><strong>Cijena</strong></th>
      </tr>

      <tr>
        <td style="text-align:right">Zidni ili podni uređaj snage do 5,5 kW</td>
        <td>300,00</td>
      </tr>

      <tr>
        <td style="text-align:right">Zidnog ili podni uređaj snage od 5,5 do 8 kW</td>
        <td>400,00</td>
      </tr>

      <tr>
        <td style="text-align:right">Zidni ili podni uređaj snage preko 8 kW</td>
        <td>Na upit</td>
      </tr>

      <tr>
        <td colspan="2" style="text-align:right">Cijene su s uračunatim PDV-om</td>
      </tr>

      <tr>
        <td colspan="2" style="text-align:left"><strong>Godišnji servis klima uređaja</strong> podrazumijeva:<br>
          <ul>
            <strong>Unutarnja jedinica:</strong>
            <li>čišćenje i dezinfekcija maske i kućišta sa filterima zrak,</li>
            <li>čišćenje isparivača kemijskim sredstvima,</li>
            <li>čišćenje kade kondenzata,</li>
            <li>čišćenje kanalica isparivača,</li>
            <li>čišćenje odvodnog crijeva kondezata propuhavanjem i vodom,</li>
            <li>čišćenje turbine,</li>
            <li>ispiranje vodom,</li>
            <li>nanošenje zaštitnog mirisnog baktericidnog premaza za dezinfekciju,</li>
            <li>mjerenje temperature usisa i ispuha,</li>
            <li>kontrola elektromehaničkih sklopova,</li>
            <li>kontrola plinske i elektroinstalacije,</li>
            <li>po potrebi ulaganje tableta u kadu kondenzata,</li>
          </ul>
          <ul>
            <strong>Vanjska jedinica:</strong>
            <li>mjerenje radnih parametara, radnog pritiska-tlakova plina,</li>
            <li>kontrola rada ventilatora,</li>
            <li>provjera mehaničkih učvršćenja,</li>
            <li>ispunjavanje i ovjera radnog naloga</li>
          </ul>
          Navedena cijena odnosi se na područje grada Zagreba, te bez dodatnog materijala (npr. plin).
        </td>
      </tr>
    </table>
  </div>
</body>

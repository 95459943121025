<link href="http://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/css/font-awesome.css" rel="stylesheet"
  type='text/css'>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<body>
  <div class="grid-container">
    <div class="header">
      <a href="/"><img src="../assets/clivent.jpg" alt="logo"></a>
      <app-navbar></app-navbar>
      <div class="contact">
        <span><i class="fa fa-phone"> +385 91 605 5802</i></span>
        <span><i class="fa fa-envelope"> info@clivent.hr</i></span>
        <span><i class="fa fa-envelope"> servis@clivent.hr</i></span>
      </div>
    </div>
    <hr>
    <router-outlet></router-outlet>
  </div>
</body>
